<template>
<v-container fluid>
  <v-img height="80vh" :src="require('../../assets/images/404.svg')" contain></v-img>
</v-container>
</template>

<script>
export default {
    name: 'error-page',
    data () {
      return {}
    },
}
</script>

<style scoped lang="scss">

</style>